.signin{
    display:block;
    width:100%;
    max-width:40rem;
    margin: 4rem auto 6rem;
    /*border:2px solid rgb(0, 0, 0);*/
}

.wrap{
    padding: 0 10px;
}

.loginbutton {
    font-size: 2.2rem;
    line-height:1;
    font-weight:400;
    text-transform: uppercase;
    display:block;
    width:100%;
    text-align:center;
    padding:0;
    margin: 0 auto;
    margin-bottom: -20px;
}

.socialSignin{
    margin: 3rem;
}

.googleContainer {
    height: 200px;
    position: relative;
    border: 3px solid green;
  }

.googleSignIn{
    width:3rem;
    display:flex;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    margin-bottom:20px;
}

.profilepic{


    border: 8px solid black;

}

.rowprofilepic{
    display:flex;
    margin-left: 20px;
    justify-content: center;
}

.helloaccounttext{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    text-align: center;
    margin-top:35px;
}

.helloaccountdiv{
    margin-left:50px;
}

.settingsdiv{
    
}

.settings{
    width:5rem;
    position: fixed;
    top:10px;
    right:10px;
}

.logoutbutton{
    position:fixed;
    top: 100px;
    right:10px;
    background-color: #00ff00;
    border:black 2px dotted;
}