.navbardiv{
    background-color: rgb(0, 255, 0);
 
    position:fixed;
    bottom:0;
    width:100%;
    height: 3.5rem;
 
    display:flex;
    justify-content: space-around;

    box-shadow: 0 -4px 6px rgba(0,0,0,.1);
 }

 .underbar{
   background-color: rgb(0, 255, 0);
   position:fixed;
    bottom:-3.5rem;
    width:100%;
    height: 3.5rem;
    align-items: center;
    display:flex;
    justify-content: space-around;

 }
 
 .icon{
    max-width: 50px;
 }

 .hide {
   display: none;
   font-weight:bold;
 }

 .hide2{
  display:none;
 }
     
 .icon:active{
   display: block;
   color: rgb(165, 39, 39);
   animation: updown 22s ease;
 }

 @keyframes updown {
   0% {
     transform: translateY(0%);
   }
 
   50% {
     transform: translateY(-10000%);
   }
 
   100% {
     transform: translateY(0%);
   }
 }

 @keyframes updownmega {
   0% {
     transform: translateY(0%);
   }
 
   50% {
     transform: translateY(-10000%);
   }
 
   100% {
     transform: translateY(0%);
   }
 }

 
 .topbar{
    position: fixed;
    top: 0px;
    width:100%;
    height: 6.5rem;
 
   display:flex;
   justify-content: center;
 
    /*background-image: url("./images/400BitBanner.png");    these are the old green rings on top */
    background-repeat: no-repeat;
    background-attachment:fixed;
    background-position: center 0vh;

    margin-bottom:1000px;

 }

 
 .topbardiv{
    position: relative;
    display: inline;
 }
 
 .hyperlink{
    display: inline-block;
    padding: 0px;
 }
 
 
 .hyperlink, i{
    margin-left: 5px; /* this is to give a 10px spacing */
 }
 
   
   @media (max-width:600px){
    .icon{
       width: 50px;
    }
 }
 
 @media (max-width:400px){
    .icon{
       width: 30px;
    }
 }