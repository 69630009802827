.collectionHeader{
    margin-top:0;
}

.collectionButton{
    width:15rem;
    box-shadow: -4px 4px 4px rgba(0,0,0,.1);
  }
  
  @media(max-height:800px){
    .collectionHeader{
        margin-top:40px;
    }
  }
  
  @media (max-width:500px), (max-height:620px){ /*window shrinks to 500width*/
  
  }
  
  @media (max-height:400px){ /*window shrinks to 420 height*/
    .collectionButton{
        width:10rem;
        box-shadow: -4px 4px 4px rgba(0,0,0,.1);
      }
      .collectionHeader{
        margin-top:70px;
    }
  }

