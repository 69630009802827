.btn{
    display:block;
    width:100%;
    padding:10px;
    margin: 0 auto;
    background: #00ff00;
    color:black;
    font-size: 1.8rem;
    line-height:1;
    font-weight:300;
    text-transform: uppercase;
    border: 0;
    outline: none;
    cursor: pointer;
}