*, ::before, ::after{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.App {

}

body{
  padding-top: 10vh;
  padding-bottom:10vh;
  margin: 0;
  display: grid;
  grid-template-rows: 11vh 1fr 8vh;
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  overflow:hidden;
}

 @media(max-width:1200px),  (max-height:800px){
    body{
      overflow: scroll;
    }
  }

.content{
  margin-top:11px;
  margin-bottom:100px;
}

.homecontainer{
  background-image: url("./images/breedsmabackground4.png");
  background-repeat: no-repeat;
  background-attachment:fixed;
  background-size: 1000px 1000px;
  background-position: center;
  height:80vh;
  animation: fadein 2s linear;
}

@keyframes fadein {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

a{
  text-decoration: none;
}

a, a:hover{
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.wrap{
  position:relative;
  max-width:1450px;
  margin: 0 auto;
}


#bigbutton{
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
}

.centersquare{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 60vh;
}

.square {
  background-color: #2ecc71;
  width: 600px;
  height: 600px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top:auto;
  margin-bottom:auto;
  width: 50%;
}

#reallogo{
  height: 250px;
  width: 250px;
  box-shadow: -4px 4px 4px 4px rgba(0,0,0,.1);
  border: 6px solid #00ff00;  
}

@media (max-width:670px), (max-height:800px){
  #reallogo{
    width: 175px;
    height: 175px;
    border: 5px solid #00ff00;  
 }
  .homecontainer{
    background-size: 750px 750px;
  }
}

@media (max-width:500px), (max-height:620px){ /*window shrinks to 500width*/
  #reallogo{
    width: 100px;
    height: 100px;
    border: 3px solid #00ff00;  
 }
  .homecontainer{
    background-size: 500px 500px;
  }
}

@media (max-height:420px){ /*window shrinks to 420 height*/
  #reallogo{
    width: 75px;
    height: 75px;
    border: 2px solid #00ff00;  
    
 }
  .homecontainer{
    background-size: 250px 250px;
  }
}


.breedsmalogo{
  position:relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
  width:20rem;
  min-height: 5rem;

  

}



.row {
  display: flex;
}

.column {
  flex: 50%;
}

.columnunequal {
  float: left;
}

.left {
  width: 35%;
}

.right {
  width: 55%;
}

.shrink{
  width:50%;
}

.columntriple {
  float: left;
  width: 33%;
}

.one{
  background-color:#09ff00c5
}

.two{
  background-color:#07c700c5
}

.three{
  background-color:#02790081
}

.rainbow{
  animation: colorRotate 2s linear 0s infinite;
}

.elysiacolors{
  animation: elysiacolors 2s linear 0s infinite;
}