/* Container to define the scrolling area */
.scrolling-container {
  position: fixed; /* Fixed to the center of the screen */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Full width for smooth scrolling */
  height: 40vh; /* Height for two rows (20vh each) */
  overflow: hidden; /* Hide overflow to ensure seamless scroll */
  background-color: rgb(255, 255, 255); /* Optional background color */
  display: flex;
  flex-direction: column; /* Stack rows vertically */
  justify-content: center;
}

/* Scrolling text animation */
.scrolling-text {
  display: flex; /* Align images horizontally */
  animation: scroll-left 15s linear infinite; /* Consistent slow speed (15s) */
  align-items: center; /* Center-align images vertically */
  height: 20vh; /* Each row takes up half the container height */
}

/* Logo styling */
.scrolling-text img {
  height: 100%; /* Scale images to fit row height */
  margin-right: 5%; /* Spacing between repeated images */
  max-width: none; /* Allow images to stretch horizontally without distortion */
}

/* Keyframes for left-to-right scrolling */
@keyframes scroll-left {
  0% {
    transform: translateX(100%); /* Start off-screen on the right */
  }
  100% {
    transform: translateX(-100%); /* Move off-screen to the left */
  }
}

/* Make it responsive */
@media screen and (max-width: 768px) {
  .scrolling-container {
    height: 50vh; /* Taller container for smaller screens */
  }

  .scrolling-text {
    animation: scroll-left 20s linear infinite; /* Slow down for smaller screens */
  }

  .scrolling-text img {
    margin-right: 3%; /* Adjust spacing for smaller screens */
  }
}
