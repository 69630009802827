.formInput{
    margin-top: 5px;
    line-height:2;
    font-weight:400;
    border:2px solid #000000;
}

.centerForm{
    width:100%;
    margin-bottom:10px;
    text-align:center;
}

.rowCenter{
    display:flex;
    justify-content: center;
}