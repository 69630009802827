.aboutblurb{
    display:flex;
    justify-content:flex-start;
    margin-bottom: -18px;
    font-weight:lighter
}

.aboutblurbholder{
  width:70%;
  margin-left:15px;
  float:left;
}

.aboutimageholder{
float:right;
width:25%;
margin-right:36px;
min-height:100px;
} 


.aboutimage{
  width:100%;
}

.aboutimagesmall{
  width:200px;
  display:none;
}


/*.aboutsmallimageholder{
  display:block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -100px;
}*/

  @media(max-width:1050px){ /*page layout 2 centers most things when smaller*/
    .aboutimage{
      display:none;
    
    }
    /*.aboutimagesmall{
      display:block;
    }*/
    .aboutblurbholder{
      width:100%;
      float:none;
      margin-left:0px;
      margin-bottom: -100px;

    }
    .aboutblurb{
      display:flex;
      justify-content:center;
      text-align:center;
    }
    .aboutHeader{
      display:flex;
      justify-content:center;
      /*.margin-bottom:-150px;*/
    }
    /*.aboutsmallimageholder{
      display:flex;
      justify-content:center;s
    }*/
    .aboutlinksbox{
      display:flex;
      justify-content: center;
    }
  }


.aboutlinksbox{
    padding-top:20px;
    margin-left:15px;
    padding-bottom: 5rem;
    display: flex;
    align-items: center;

    gap: 50px;
    background-color: white;
    width:100%;
  }
  
  .abouticonlink{
    width: 5rem;
    box-shadow: -2px 2px 4px rgba(0,0,0,.1);
  }
  
  .aboutHeader{
    width:100%;
    margin-top: -50px;
    margin-left:15px;
    /*background-color:goldenrod*/
  }

  .rainbowareal{
    color:#00ff00;
    background-color:rgb(8, 136, 46);
    border:solid 1px rgb(0, 0, 0);
    box-shadow: -2px 2px 4px 1px rgba(0,0,0,0.5);
    margin-left: 3px;
    margin-right: 2px;
  }

  .rainbowareal:visited{
    color:#00ff00
  }

  .rainbowareal:active{
        text-align: center;
        text-decoration: underline;
        font-size: 64px;
        font-family: monospace;
        letter-spacing: 5px;
        animation: colorRotate 2s linear 0s infinite;
  }

  .rainbowaelysia{
    color:#0000ff;
    background-color:rgb(93, 175, 252);
    border:solid 1px black;
    box-shadow: -2px 2px 4px 1px rgba(0,0,0,0.5);
    margin-left: 3px;
    margin-right: 2px;
  }

  .rainbowaelysia:visited{
    color:#0000ff;
  }

  .rainbowaelysia:active{
        text-align: center;
        text-decoration: underline;
        font-size: 64px;
        font-family: monospace;
        letter-spacing: 5px;
        animation: colorRotate 2s linear 0s infinite;
  }

  @keyframes colorRotate {
    from {
      color: #6666ff;
    }
    10% {
      color: #0099ff;
    }
    50% {
      color: #00ff00;
    }
    75% {
      color: #ff3399;
    }
    100% {
      color: #6666ff;
    }
  }


  @keyframes elysiacolors {
    from {
      color:#5bcefa
    }
    33.33% {
      color: #f5a9b8;
    }
    66.666% {
      color: #ffffff;
    }
    100% {
      color:#5bcefa
    }
  }

