.signup{
    margin:0 auto;
}

.wrap{
    padding: 0 10px;
}

.signupheader {
    font-size: 2.2rem;
    line-height:1;
    font-weight:400;
    text-transform: uppercase;
    display:block;
    width:100%;
    text-align:center;
    padding:0;
    margin: 10px auto;
}

.socialSignin{
    margin: 3rem;
}

.registerErrorsList{
    list-style:none;
    color:red;
    text-align: center;
    margin:0 auto;
    font-weight: bold;
    font-size:32px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.passwordRequirementsDiv{
    opacity: 0.8;
    margin-left: 10px;
    font-weight:bold;
    
}

.hoverBox{
    display:none;
}

.passwordRequirementsDiv:hover{
    background-color: rgb(131, 5, 5);
}

.passwordRequirementsDiv:hover .hoverBox{
    display:block;
}

